body {
  margin: 0;
  font-family: consolas, monospace;
  color: green;
  background-color: black;
}
a {
  color: inherit;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
