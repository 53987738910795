.App {
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
}

main {
  flex-grow: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.banner {
  margin: 0 auto;
  max-width: 100%;
  font-size: 1.3vw;
}
.header {
  text-align: center;
  margin: 60px 30px 30px;
}
article {
  min-width: 700px;
}